<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg bg-white rounded-lg flex items-center p-5">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-5">
                    <label class="text-gray-400">
                        提領人 手機
                        <div class="flex">
                            <div class="phone-select flex-1">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div class="flex-1">
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-5">
                    <label class="text-gray-400">
                        <div>申請日期 範圍</div>
                        <el-date-picker
                            v-model="dateInputs"
                            size="small"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="col-span-2 flex items-end justify-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px]" @click="filterSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px]" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white mt-2">
            <OrderTable :datas="datas" :choiceDatas.sync="choiceDatas" />
            <div class="justify-center mt-3 flex mb-3">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// 導入表格
import OrderTable from "./components/Table.vue";
// 搜尋條件預設資料
const defaultFilterOpts = {
    phone_country: "886",
    status: [-1],
    reason: "CHARGE_OFF",
    created_date_start: "",
    created_date_end: "",
};

export default {
    name: "SubmitApplication",
    components: {
        LoadingComponent,
        MyPagination,
        OrderTable,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            return {
                ...this.filterOpts,
                phone: phone,
                limit: this.paginationData.limit,
                page: this.currentPage,
                phone_country: "886",
            };
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: true,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 0,
            filterOpts: { ...defaultFilterOpts },
            // 時間範圍
            dateInputs: [],
            // 選中的訂單
            choiceDatas: [],
        };
    },
    methods: {
        // 列表搜尋,分頁相關
        async getList() {
            this.loading = true;
            this.choiceDatas = [];
            try {
                const { data } = await this.$api.GetBrokerWaitRemittancesApi(this.filterData);
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
            } finally {
                this.loading = false;
            }
        },
        /**
         * 搜尋
         */
        filterSearch() {
            this.currentPage = 1;
            this.getList();
        },
        /**
         * 重置
         */
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        /**
         * 每頁筆數顯示切換
         */
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        /**
         * 分頁切換
         */
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.created_date_start = "";
                this.filterOpts.created_date_end = "";
                return;
            }
            this.filterOpts.created_date_start = val[0];
            this.filterOpts.created_date_end = val[1];
        },
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            // 判斷有輸入值為 0 開頭時 捨棄 0 因為國碼搜尋不用 0 開頭
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
    mounted() {
        this.getList();
    },
    activated() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.choose-number {
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    margin-right: 30px;
}

.phone-select {
    width: 150px;
    margin-right: 5px;
}

.action-bar {
    background: white;
    color: chocolate;
    margin: 10px 0;
    height: 55px;
}

.checkbox-th {
    min-width: auto;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
