<template>
    <div class="overflow-x-auto bg-white">
        <table class="myTable">
            <thead>
                <tr>
                    <th class="w-[20px] checkbox-th">#</th>
                    <th>提領人 暱稱</th>
                    <th>金額</th>
                    <th>申請時間</th>
                    <th>查看</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                    <td>
                        <el-checkbox :value="hasChecked(item.id)" @change="toggleCheck(item.id)"></el-checkbox>
                    </td>
                    <td>{{ item.broker.name }}</td>
                    <td>${{ item.amount | formatCurrency }}</td>
                    <td>{{ item.created_at | formatDateTime }}</td>
                    <td class="cursor-pointer" @click="goDetail(item.id)">
                        <i class="far fa-edit"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        datas: {
            type: Array,
        },
        choiceDatas: {
            type: Array,
        },
    },
    data() {
        return {};
    },
    methods: {
        /**
         * 選中的 id
         * @param { type String(字串) } id 訂單 id
         */
        toggleCheck(id) {
            // 判斷是否有資料
            const hasChoice = this.choiceDatas.indexOf(id) !== -1;
            let choiceDatas = this.choiceDatas;
            // 判斷是否選中的資料 再以選中的資料中 要移除選取
            if (hasChoice) {
                console.log("choiced", this.choiceDatas);
                // 移除選取方法
                choiceDatas = choiceDatas.filter((item) => {
                    return item !== id;
                });
            } else {
                console.log("un choiced", choiceDatas);
                // 新增選取雌料
                choiceDatas.push(id);
            }
            this.$emit("update:choiceDatas", choiceDatas);
        },
        /**
         * 以選中的訂單
         * @param { type Number(數字) } index 選中訂單的 index 索引位置
         */
        hasChecked(index) {
            return this.choiceDatas.indexOf(index) !== -1;
        },
        /**
         * 導向細節頁
         * @param { type String(字串) } paymentID 訂單 id
         */
        goDetail(id) {
            this.$router.push({
                name: "salary_withdraw_broker_detail",
                params: {
                    id,
                },
                query: {
                    pre: this.$route.name,
                },
            });
        },
    },
};
</script>
